
import router from '@/router';

import CreateDiscountPopoverService from '../services/createDiscountPopoverService';
import LocationService from '../services/LocationService';
import ItemService from '../services/ItemService';
import { DiscountType } from '@/enums/DiscountType';
import RoleService from '../services/RoleService';
import LocalStorageService from '../services/LocalStorageService';
import { getUniqueListBy } from '@/helper/validations';
// @ts-ignore
import { sortBy } from 'lodash';
import SearchService from '@/services/SearchService';
import DatePicker from '@/shared/DatePicker.vue';
import moment from 'moment';

import { isValidRange } from '@/helper/validations';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DiscountDetail',
  components: {
    DatePicker,
  },
  data() {
    const data = {
      itemType: '1,2',
      sortBy: 'name',
      sortType: 'ASC',
      applyAllGoods: '',
      selectApplyToSpecificItems: '',
      countLoading: 0,
      setDiscardChangesModal: false,
      showDiscardChangesModal: false,
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',
      searchTimeout: undefined,
      formErro: {},
      fromdate: '',
      datepickerSetting: undefined,
      todate: undefined,
      todatepickerSetting: undefined,

      locationService: undefined,
      itemService: undefined,
      discountService: undefined,
      localStorageService: undefined,
      roleService: undefined,
      searchService: undefined,

      redirect: false,
      access: {},
      isEditMode: false,
      discountAction: 'Create',
      showAuditTable: false,
      changesQueried: false,
      dateFilterFrom: null,
      dateFilterTo: null,
      selectedLocations: [],
      allLocations: [],
      isDisabled: false,
      allItemsSelected: false,
      // dateSelected: boolean = false,
      selectedItems: [],
      selectedAllServices: [],
      selectedAllItems: [],
      allItems: [],
      createTaxDisabled: false,
      loading: false,
      locationId: '',
      isSuperadminLoc: false,
      isDiscountPageUpdated: false,
      searchTerm: '',
      filteredLeftItems: [],
      filteredRightItems: [],
      leftItems: [],
      rightItems: [],
      discountOptions: [
        { value: DiscountType.Monetary, text: '$' },
        { value: DiscountType.Percentage, text: '%' },
      ],
      breadcrumbs: [
        {
          text: 'Discounts',
          href: '#/discounts',
        },
        {
          text: 'Edit Discount',
          active: true,
        },
      ],

      discount: {
        id: '',
        name: '',
        value: 0,
        locationId: '',
        discountType: 0,
        discountFrom: null,
        discountTo: null,
        applyToAllGoods: false,
        applyToSpecificItems: false,
        items: [],
        locationDiscount: [],
      },
      pageCount: 1,
      pageFlag: true,
      alertTitle: '',
      alertMessage: '',
      showConfirm: false,
      state: 0,
    };
    return data;
  },
  created() {
    this.locationService = new LocationService();
    this.itemService = new ItemService();
    this.discountService = new CreateDiscountPopoverService();
    this.localStorageService = new LocalStorageService();
    this.roleService = new RoleService(new LocalStorageService());
    this.searchService = new SearchService();
    this.datepickerSetting = {
      id: 'fromdateId',
      name: 'fromDateName',
      class: 'myDateInput',
      yearMinus: '0',
      locale: {
        weekday: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        todayBtn: 'Today',
        clearBtn: 'Clear',
        closeBtn: 'Close',
      },
      // changeEvent: (value:any) => {
      //   this.fromdate = value;
      // },
      changeEvent: (value: any) => {
        //FromDate
        this.isDisabled = false;
        if (
          this.todate != '' &&
          this.todate != null &&
          this.todate != undefined &&
          moment(this.todate) &&
          moment(value) > moment(this.todate)
        ) {
          this.alertTitle = 'Alert';
          this.alertMessage = 'FromDate should be smaller than the ToDate';
          this.showConfirm = true;
          this.isDisabled = true;
          return;
        }
        this.fromdate = value;
      },
    };
    this.todatepickerSetting = {
      id: 'todateId',
      name: 'todateName',
      class: 'myDateInput',
      yearMinus: '0',
      locale: {
        weekday: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        todayBtn: 'Today',
        clearBtn: 'Clear',
        closeBtn: 'Close',
      },
      // changeEvent: (value:any) => {
      //   this.todate = value;
      // },
      changeEvent: (value: any) => {
        //Todate
        this.isDisabled = false;
        if (
          this.fromdate != '' &&
          this.fromdate != null &&
          this.fromdate != undefined &&
          moment(this.fromdate) &&
          moment(this.fromdate) > moment(value)
        ) {
          this.alertTitle = 'Alert';
          this.alertMessage = 'ToDate should be greater than the FromDate';
          this.showConfirm = true;
          this.isDisabled = true;
          return;
        }
        this.todate = value;
      },
    };
  },
  watch: {
    discount: {
      handler(newValue) {
        if (this.countLoading > 0) {
          this.setDiscardChangesModal = true;
        }
        this.countLoading++;
      },
      deep: true,
    },

    selectedItems(newVal: string[], oldVal: string[]) {
      if (newVal.length === this.allItems.length) {
        this.allItemsSelected = true;
      } else {
        this.allItemsSelected = false;
      }
    },
  },
  async mounted() {
    this.isSuperadminLoc = false;

    await this.$store.dispatch('getLocationList');
    const locationResp = this.$store.getters.allLocationList;

    this.allLocations = locationResp;

    if (this.$route.query.locationId) {
      this.locationId = this.$route.query.locationId;
      this.breadcrumbs[0].href = `#/discounts?locationId=${this.locationId}`;
    }
    const locationOfSuperadmin = this.localStorageService.getItem('locations');
    if (
      locationOfSuperadmin &&
      locationOfSuperadmin.slice(2, -2) !== this.$route.query.locationId &&
      this.roleService.isSuperadmin
    ) {
      this.isSuperadminLoc = true;
    }
    this.loading = true;

    const discountId = this.$route.params.id;
    this.setIsEditMode(discountId);

    if (this.isEditMode) {
      this.discountAction = 'Edit';
      this.breadcrumbs[1].text = 'Edit Discount';
    } else {
      this.selectedLocations.push(this.$route.query.locationId.toString());
    }

    this.findOrInitializeAllLocationDiscounts();

    if (this.isEditMode) {
      const discountPromise =
        await this.discountService.getLocationDiscountByID(
          discountId,
          this.$route.query.locationId.toString(),
        );
      const result = JSON.parse(JSON.stringify(discountPromise.discount));
      result.isDeleted = 0;
      this.discount = result;
      this.discount.discountType = result.discountType.data[0];
      if (result.discountFrom !== null) {
        // this.dateSelected = true
        this.dateFilterFrom = new Date(result.discountFrom);
        this.dateFilterTo = new Date(result.discountTo);
        this.datepickerSetting.changeEvent(
          this.getDateFormat(this.dateFilterFrom),
        );
        this.todatepickerSetting.changeEvent(
          this.getDateFormat(this.dateFilterTo),
        );
      }
      this.discount.items = result.itemDiscount.map((itemDis: any) => {
        return itemDis.item;
      });
      this.selectedItems = this.discount.items!.map((item) => item.id);
      this.selectedItems = [...new Set(this.selectedItems)];

      this.selectedLocations = this.discount.locationDiscount
        .filter((locationDiscount: any) => !locationDiscount.isDeleted)
        .map((locationDiscount: any) => locationDiscount.location!.id);
    }
    if (this.$route.query.locationId) {
      const itemResp = await this.itemService.getAllItemsFor(
        1,
        200,
        '',
        '',
        this.selectedLocations,
        this.sortBy,
        this.sortType,
        this.itemType,
      );
      this.allItems = itemResp.data.items;
    } else {
      const itemResp = await this.itemService.getAllItems();
      this.allItems = itemResp.data.items;
    }
    // const halflength = Math.ceil(this.allItems.length / 2)
    const leftItems = this.allItems; //.slice(0, halflength)
    // const rightItems = this.allItems.slice(halflength, this.allItems.length)
    this.filteredLeftItems = sortBy(leftItems, (o: any) =>
      o.name.toLowerCase(),
    );
    // this.filteredRightItems = sortBy(rightItems, (o: any) => o.name)
    this.leftItems = this.filteredLeftItems;
    // this.rightItems = this.filteredRightItems
    if (this.selectedItems.length === this.allItems.length) {
      this.allItemsSelected = true;
    } else {
      this.allItemsSelected = false;
    }
    this.loading = false;
  },
  methods: {
    showTooltip() {
      const button = document.getElementById('myTooltip');
      const tooltip = document.createElement('div');

      tooltip.classList.add('tooltip');
      tooltip.textContent =
        'Used to temporarily apply discounts to 1 or many products. The day after the set End Date, the discount will no longer be automatically applied to any product.';
      button.parentNode.appendChild(tooltip);
      tooltip.style.top = button.offsetTop + button.offsetHeight - 10 + 'px';
      tooltip.style.left = button.offsetLeft + button.offsetWidth / 2 + 'px';
      tooltip.classList.add('show');

      // Hide tooltip after 3 seconds
      setTimeout(function () {
        tooltip.classList.remove('show');
        tooltip.parentNode.removeChild(tooltip);
      }, 6000);
    },

    getDateFormat(val: any) {
      if (val === 'from') {
        const _return_day = this.dateFilterFrom;
        if (_return_day === null) {
          return '';
        } else {
          return (
            (_return_day.getMonth() > 8
              ? _return_day.getMonth() + 1
              : '0' + (_return_day.getMonth() + 1)) +
            '/' +
            (_return_day.getDate() > 9
              ? _return_day.getDate()
              : '0' + _return_day.getDate()) +
            '/' +
            _return_day.getFullYear()
          );
        }
      } else if (val === 'to') {
        const return_day = this.dateFilterTo;
        if (return_day === null) {
          return '';
        } else {
          return (
            (return_day.getMonth() > 8
              ? return_day.getMonth() + 1
              : '0' + (return_day.getMonth() + 1)) +
            '/' +
            (return_day.getDate() > 9
              ? return_day.getDate()
              : '0' + return_day.getDate()) +
            '/' +
            return_day.getFullYear()
          );
        }
      }
      return '';
    },

    scrollFunction(obj: any) {
      const scrollHeight = document.getElementById('scrollDiv')?.scrollHeight;
      const clientHeight = document.getElementById('scrollDiv')?.clientHeight;
      const scrollTop = document.getElementById('scrollDiv')?.scrollTop;
      let scrollHeightNumber = 0;
      let scrollTopNumber = 0;
      if (scrollHeight && clientHeight && scrollTop) {
        scrollHeightNumber = scrollHeight - clientHeight;
        scrollTopNumber = scrollTop;
      }
      const topNeve = document.getElementById('scrollDiv');
      if (this.pageFlag && topNeve) {
        // if((scrollTopNumber > (scrollHeightNumber-500) && scrollTopNumber < (scrollHeightNumber-450)) && scrollHeightNumber != 0){
        if (
          scrollTopNumber > scrollHeightNumber - 10 &&
          scrollHeightNumber != 0
        ) {
          // topNeve.scrollTop = 100
          this.pageFlag = false;
          this.pageCount++;
          this.conductSearch();
        }
      }
    },

    searchByText() {
      this.leftItems = [];
      this.pageFlag = false;
      this.pageCount = 1;
      this.conductSearch();
    },

    searchByTextDebouncing() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      // Search starts when user not pressed any key for 1000ms
      this.searchTimeout = setTimeout(() => {
        this.searchByText();
      }, 1000);
    },

    async conductSearch() {
      this.loading = true;
      if (this.searchTerm) {
        const itemResp = await this.itemService.getAllItemsFor(
          this.pageCount,
          200,
          this.searchTerm,
          '',
          this.selectedLocations,
          this.sortBy,
          this.sortType,
          this.itemType,
        );
        this.allItems = itemResp.data.items;
        const leftItems = this.allItems;
        this.filteredLeftItems = sortBy(leftItems, (o: any) =>
          o.name.toLowerCase(),
        );
        this.leftItems = [...this.leftItems, ...this.filteredLeftItems];
        if (this.leftItems.length < itemResp.data.totalCount) {
          this.pageFlag = true;
        }
      } else {
        const itemResp = await this.itemService.getAllItemsFor(
          this.pageCount,
          200,
          '',
          '',
          this.selectedLocations,
          this.sortBy,
          this.sortType,
          this.itemType,
        );
        this.allItems = itemResp.data.items;
        const leftItems = this.allItems;
        this.filteredLeftItems = sortBy(leftItems, (o: any) =>
          o.name.toLowerCase(),
        );
        this.leftItems = [...this.leftItems, ...this.filteredLeftItems];
        if (this.leftItems.length < itemResp.data.totalCount) {
          this.pageFlag = true;
        }
        // this.rightItems = this.filteredRightItems
      }
      this.loading = false;
    },

    changeDiscount() {
      this.isDiscountPageUpdated = true;
      this.validation('');
    },

    setIsEditMode(discountId: any): void {
      this.isEditMode = discountId !== undefined && discountId !== null;
    },

    validation($event: any) {
      this.createTaxDisabled = false;
      this.isDisabled = false;
      this.formErro = {};
      if (
        this.discount.discountType === 0 &&
        (this.discount.value < 1 || this.discount.value > 99)
      ) {
        this.formErro.discount =
          'Discount Percentage should be between 1 to 99';
        this.createTaxDisabled = true;
        this.isDisabled = true;
      }

      if (
        this.discount.discountType === 1 &&
        !isValidRange(this.discount.value, 0.01, 999999)
      ) {
        this.formErro.discount =
          'Discount value should be in range 0.01 to 999999';
        this.createTaxDisabled = true;
        this.isDisabled = true;
      }
      if (
        $event != null &&
        $event.indexOf('.') > 0 &&
        $event.split('.')[1].length > 3
      ) {
        this.createTaxDisabled = true;
      } else {
        this.createTaxDisabled = false;
      }
    },

    disabledBtn() {
      this.isDisabled = true;
    },

    handleCancel() {
      if (this.setDiscardChangesModal) {
        this.redirect = true;
        this.showDiscardChangesModal = true;
        return;
      }
      this.navigateAway();
    },

    navigateAway() {
      if (this.redirect) {
        router.push({
          path: `/discounts`,
          query: { locationId: this.locationId },
        });
      } else {
        this.showConfirm = false;
      }
    },

    changeDiscountItems(event: any) {
      this.isDiscountPageUpdated = true;
      this.selectedItems = [...new Set(this.selectedItems)];
      if (event.target.checked) {
        this.selectedItems.forEach((itemId) => {
          const item = this.allItems.find((item) => item.id === itemId);
          if (item) {
            if (this.discount.items) {
              this.discount.items.push(item);
            }
          }
        });
      } else {
        if (this.discount.items) {
          const itemsIndex = this.discount.items.findIndex(
            (item: any) => event.target.value === item.id,
          );
          this.discount.items.splice(itemsIndex, 1);
        }
      }
      this.selectedItems = [...new Set(this.selectedItems)];
    },

    async handleSubmit() {
      try {
        const word = this.isEditMode ? 'updated' : 'added';
        const date = new Date();
        let hasDates = false;
        date.setHours(0, 0, 0, 0);
        const currentDate = new Date(date).getTime();
        const fromDate = new Date(this.fromdate).getTime();
        const toDate = new Date(this.todate).getTime();

        this.loading = true;
        this.isDisabled = true;
        if (this.checkValidation()) {
          if (
            (this.fromdate !== '' &&
              this.fromdate.length > 0 &&
              this.todate.length === 0) ||
            (this.fromdate !== '' &&
              this.todate.length > 0 &&
              this.fromdate.length === 0)
          ) {
            this.formErro.rangeissue = 'Date range is required !';
            this.loading = false;
            this.isDisabled = false;
            return false;
          }

          if (
            this.fromdate !== '' &&
            this.fromdate.length > 0 &&
            this.todate.length > 0
          ) {
            hasDates = true;
          }

          if (hasDates && fromDate < currentDate) {
            this.formErro.rangeissue =
              'Start date must be equal or greater than today date!';
            this.loading = false;
            this.isDisabled = false;
            return false;
          }

          if (hasDates && toDate < currentDate) {
            this.formErro.rangeissue =
              'End date must be equal or greater than today date!';
            this.loading = false;
            this.isDisabled = false;
            return false;
          }

          if (
            this.selectedLocations.length === 0 ||
            !this.selectedLocations.length
          ) {
            this.formErro.location = 'Please select at least one location.';
            this.loading = false;
            this.isDisabled = false;
            return false;
          }

          this.discount.discountFrom = new Date(this.fromdate);
          this.discount.discountTo = new Date(this.todate + ' 23:59:59');

          if (
            Array.isArray(this.discount.items) &&
            this.discount.items.length > 0
          ) {
            this.discount.items = getUniqueListBy(this.discount.items, 'id');
            this.discount.items = this.discount.items.filter((item: any) =>
              this.selectedItems.includes(item.id),
            );
          }

          this.redirect = true;

          const response = await this.discountService.updateLocationDiscount(
            this.discount,
            this.locationId,
            this.selectedLocations,
            this.allLocations,
          );
          const discountId = response.data.discount.id;

          if (discountId !== undefined || discountId !== null) {
            const discount = { ...this.discount, id: discountId };
            await this.discountService.saveDiscountItems(
              discount,
              this.locationId,
            );
          }

          this.disabledBtn();
          this.loading = false;
          this.isDiscountPageUpdated = false;

          this.alertTitle = 'Success';
          this.alertMessage = `Discount ${word} successfully`;
          this.showConfirm = true;
          this.isDisabled = true;

          setTimeout(() => {
            router.push(`/discounts?locationId=${this.locationId}`);
          }, 1500);
        } else {
          // alert('Please check all required fields')
          this.loading = false;
          this.isDisabled = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'Error';
        this.messageNotification =
          ' Unable to update discount due to internal server issue.';
      }
    },

    checkValidation() {
      let returnValue = true;
      this.formErro = {};
      if (this.discount.name.trim() === '') {
        this.formErro.name = 'Name is required';
        returnValue = false;
      }
      if (this.discount.value === 0 || this.discount.value.toString() === '') {
        this.formErro.discount = 'Discount value is required';
        returnValue = false;
      }

      if (this.discount.discountType != 0 && this.discount.discountType != 1) {
        this.formErro.discountType = 'Discount type is required';
        returnValue = false;
      }
      if (
        this.discount.discountType === 0 &&
        (this.discount.value < 1 || this.discount.value > 99)
      ) {
        this.formErro.discount =
          'Discount Percentage should be between 1 to 99';
        returnValue = false;
      }
      if (
        this.discount.discountType === 1 &&
        !isValidRange(this.discount.value, 0.01, 999999)
      ) {
        this.formErro.discount =
          'Discount value should be in range 0.01 to 999999';
        returnValue = false;
      }
      if (this.fromdate !== null && this.todate !== null) {
        if (
          new Date(this.fromdate).getTime() > new Date(this.todate).getTime()
        ) {
          this.formErro.rangeissue = 'Please select correct Date Range';
          returnValue = false;
        }
      } else if (this.fromdate === null && this.todate === null) {
        returnValue = true;
      } else {
        this.formErro.rangeissue = 'Please select correct Date Range';
        returnValue = false;
      }

      return returnValue;
    },

    IsAlphaNumeric(e: any) {
      const keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
      const ret = (keyCode >= 48 && keyCode <= 57) || keyCode == 46;
      return ret;
    },

    navigateToHome() {
      if (this.isDiscountPageUpdated) {
        this.alertTitle = 'Confirm';
        this.alertMessage = 'Do you really want to cancel?';
        this.state = 1;
        this.showConfirm = true;
      } else {
        router.push({ path: `/` });
      }
    },

    positiveButton() {
      this.showConfirm = false;
      this.isDiscountPageUpdated = false;
      if (this.state === 1) {
        router.push({ path: `/` });
      } else if (this.state === 2) {
        router.push({
          path: `/settings`,
          query: { locationId: this.locationId },
        });
      } else if (this.state === 3) {
        router.push({
          path: `/discounts`,
          query: { locationId: this.locationId },
        });
      }
    },

    negativeButton() {
      this.showConfirm = false;
    },

    navigateToSettings() {
      if (this.isDiscountPageUpdated) {
        this.alertTitle = 'Confirm';
        this.alertMessage = 'Do you really want to cancel?';
        this.state = 2;
        this.showConfirm = true;
      } else {
        router.push({
          path: `/settings`,
          query: { locationId: this.locationId },
        });
      }
    },

    navigateToDiscountList() {
      if (this.isDiscountPageUpdated) {
        this.alertTitle = 'Confirm';
        this.alertMessage = 'Do you really want to cancel?';
        this.state = 3;
        this.showConfirm = true;
      } else {
        router.push({
          path: `/discounts`,
          query: { locationId: this.locationId },
        });
      }
    },

    async getItemsForLocations(toggleApplyToSpecificItems = false) {
      this.selectApplyToSpecificItems = 'add';
      if (toggleApplyToSpecificItems) {
        this.discount.applyToAllGoods = false;
      }
      this.loading = true;
      const itemResp = await this.itemService.getAllItemsFor(
        1,
        150,
        '',
        '',
        this.selectedLocations,
        this.sortBy,
        this.sortType,
        this.itemType,
      );
      this.allItems = itemResp.data.items;
      const leftItems = this.allItems;
      this.filteredLeftItems = sortBy(leftItems, (o: any) =>
        o.name.toLowerCase(),
      );
      this.leftItems = this.filteredLeftItems;
      this.selectedItems = [];
      this.loading = false;
    },

    toggleApplyAllGoods(event: any) {
      if (event.target.checked) {
        this.applyAllGoods = 'add';
        this.discount.applyToSpecificItems = false;
      } else {
        this.applyAllGoods = 'remove';
      }
    },

    async toggleApplyToSpecificItems(event: any) {
      this.allItems = [];
      const leftItems = this.allItems;
      this.filteredLeftItems = sortBy(leftItems, (o: any) =>
        o.name.toLowerCase(),
      );
      this.leftItems = this.filteredLeftItems;
      this.selectedItems = [];

      if (event.target.checked) {
        if (this.selectedLocations.length > 0)
          await this.getItemsForLocations(true);
      } else {
        this.selectApplyToSpecificItems = 'remove';
        this.selectedItems = [];
      }
    },

    findOrInitializeLocationTax(location: any): any {
      // @ts-ignore
      let locationDiscount: any = this.discount.locationDiscount.find(
        (locationDiscount) => {
          return (
            locationDiscount.location &&
            locationDiscount.location.id === location.id
          );
        },
      );

      if (locationDiscount === undefined) {
        locationDiscount = { location: location };
        this.discount.locationDiscount.push(locationDiscount);
      }
      return locationDiscount;
    },

    async conductItemFilterForLocation(location: any) {
      this.filteredLeftItems = [];
      this.selectedItems = [];
      if (this.selectedLocations.length > 0) await this.getItemsForLocations();
      this.leftItems = [];
      this.rightItems = [];
      if (location.length) {
        location.forEach((locationId: any) => {
          const elements = this.filteredLeftItems.filter((item: any) => {
            return item.locations.some((vl: any) => {
              return vl.id === locationId;
            });
          });
          this.leftItems = [...this.leftItems, ...elements];
          const _elements = this.filteredRightItems.filter((item: any) => {
            return item.locations.some((vl: any) => {
              return vl.id === locationId;
            });
          });
          this.rightItems = [...this.rightItems, ..._elements];
        });

        const leftItems = this.leftItems;
        this.leftItems = sortBy(leftItems, (o: any) => o.name.toLowerCase());

        const temp: any = {};
        this.leftItems.forEach((element: any) => {
          temp[element.id] = element;
        });

        const _temp: any = {};
        this.rightItems.forEach((element: any) => {
          _temp[element.id] = element;
        });
        this.leftItems = Object.values(temp);
        this.rightItems = Object.values(_temp);
      }

      const itemsSelected = this.discount.items.map((item: any) => item.id);
      this.selectedItems = [...new Set(itemsSelected)];
    },

    findOrInitializeAllLocationDiscounts() {
      this.allLocations.forEach((location) => {
        this.findOrInitializeLocationTax(location);
      });
    },

    async toggleLocation(newVal: any) {
      await this.conductItemFilterForLocation(this.selectedLocations);
    },
  },
});
